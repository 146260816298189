body {
  background-color: #282c34;
  color: white;
}

.App-layout {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.App-header {
  padding: 1em;
}

.vision {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.vision-layout {
  display: flex;
  flex-direction: column;
}

.vision table {
  border-collapse: collapse;
}

.vision table td {
  border: 0.1em solid green;
  width: 5em;
  height: 3em;
  font-size: 3em;
  min-width: 5em;
  min-height: 3em;
}

.vision-leftright {
  text-transform: uppercase;
}

.vision-pqdb {
  text-transform: lowercase;
}

.buttonbar {
  display: flex;
  align-self: flex-end;
  padding: 0.5em 0;
}